


































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';

export default defineComponent({
  name: 'uOttawa1321Lab5Question2',
  components: {STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: 'Can you explain why the (2R,3S) and (2S,3R) enantiomeric pair has a melting point that is over 100ºC higher than the (2S,3S) and (2R,3R) enantiomeric pair?',
          fr: "Pouvez-vous expliquer pourquoi la paire d'énantiomères (2R,3S) et (2S,3R) a un point de fusion supérieur de plus de 100ºC à celui de la paire d'énantiomères (2S,3S) et (2R,3R) ?",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
